import React from 'react'
import LoginOne from '../login-1'

const Login = () => {
	return (
		<LoginOne allowRedirect={true} />
	)
}

export default Login
