import enLang from './entries/en_US';

interface AppLocaleType {
    en: LocaleType;
}

interface LocaleType {
    antd: any;
    locale: string;
    messages: any
}

const AppLocale:AppLocaleType = {
    en: enLang,
};

export default AppLocale;