import { gql } from '@apollo/client'

const SignIn  = gql`
  mutation signIn(
    $email: String!
    $password: String!
  ) {
    signIn(
      data: {
        email: $email
        password: $password
      }
    ) {
      id
      access_token
      id_token
      name
      email
      admin
      picture
      companies {
        id
        role
        company {
          id
          name
          demGroups {
            id
            groupId
          }
        }
      }
    }
  }
`

export interface SignInArgs {
  email: string;
  password: string;
} 

export interface SignInRes {
  signIn: {
    id: string
    access_token: string;
    id_token: string;
    name: string;
    email: string;
    admin: boolean;
    picture: string;
    companies: {
      id: string;
      role: string;
      company: {
        id: string;
        name: string;
        demGroups: {
          id: string;
          groupId: string;
        }[]
      }
    }[]
  }
}

export default SignIn