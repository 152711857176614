import { action, Action } from "easy-peasy";

interface DemGroup {
  id: string;
  groupId: string;
}

interface Company {
  id: string;
  role: string;
  company: {
    id: string;
    name: string;
    demGroups: {
      id: string;
      groupId: string
    }[]
  }
}

export interface SetUserPayload {
  id: string;
  email: string;
  name: string;
  picture: string;
  admin: boolean;
  companies: Company[];
}

export interface UserModel {
  id: string;
  email: string;
  name: string;
  picture: string;
  admin: boolean;
  demGroups: DemGroup[];
  companies: Company[]|undefined;
  currentCompany: Company|null;

  setUser: Action<UserModel, SetUserPayload>;
  clearUser: Action<UserModel>;
  setDemGroups: Action<UserModel, DemGroup[]>
}

const userModel: UserModel = {
  id: '',
  email: '',
  name: '',
  picture: '',
  admin: false,
  demGroups: [],
  companies: [],
  currentCompany: null,

  setUser: action((state, payload) => {
    state.id = payload.id;
    state.email = payload.email;
    state.name = payload.name;
    state.picture = payload.picture;
    state.admin = payload.admin
    state.companies = payload.companies
    state.demGroups = payload.companies ?  payload.companies[0].company.demGroups : []
    state.currentCompany = payload.companies ? payload.companies[0] : null
  }),
  clearUser: action((state) => {
    state.id = '';
    state.email = '';
    state.name = ''
    state.picture = '';
    state.admin = false;
    state.demGroups = [];
    state.companies = [];
    state.currentCompany = null;
  }),
  setDemGroups: action((state, payload) => {
    state.demGroups = payload
  }),
};

export default userModel;
