import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useStoreState } from "state";
import useAuth from 'hooks/useAuth'

export const AppViews = () => {
  const { getCurrentUser } = useAuth()

  const adminRoutes = [
    <Route
      path={`${APP_PREFIX_PATH}/companies`}
      component={lazy(() => import(`./admin-views/companies`))}
    />,
    <Route
      path={`${APP_PREFIX_PATH}/staff`}
      component={lazy(() => import(`./admin-views/companies/staff`))}
    />,
    <Route
      path={`${APP_PREFIX_PATH}/stock`}
      component={lazy(() => import(`./admin-views/stock-management`))}
    />,
  ];

  const userViews = [
    <Route
      path={`${APP_PREFIX_PATH}/evidence`}
      component={lazy(() => import(`./user-views/evidence`))}
    />,
    <Route
      path={`${APP_PREFIX_PATH}/portal-users`}
      component={lazy(() => import(`./user-views/portal-users`))}
    />,
  ];

  const isAdmin = useStoreState((state) => state.user.admin);

  useEffect(() => {
    getCurrentUser()
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        /> */}

        {isAdmin ? adminRoutes : userViews}

        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={
            isAdmin
              ? `${APP_PREFIX_PATH}/companies`
              : `${APP_PREFIX_PATH}/evidence`
          }
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
