import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig, { NavItem } from "configs/NavigationConfig";
import utils from "utils";
import { useStoreState, useStoreActions, NavType, SideNavTheme } from "state";
import { AccountTypes, Roles } from 'types'

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn: boolean, localeKey: string) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key: string) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

interface SideNavContentProps {
  sideNavTheme: SideNavTheme;
  routeInfo: NavItem;
  hideGroupTitle?: boolean;
  localization: boolean;
  onMobileNavToggle(value: boolean): void;
}

const SideNavContent = (props: SideNavContentProps) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const userIsAdmin = useStoreState((state) => state.user.admin);
  const currentCompany = useStoreState((state) => state.user.currentCompany);

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
    <Menu
      theme={sideNavTheme === SideNavTheme.LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig
        .filter(({ accountTypes, roles }) => {
          if (accountTypes.includes(AccountTypes.admin)) {
            return userIsAdmin
          } else if (accountTypes.includes(AccountTypes.company)) {
            if (!userIsAdmin) {
              if (roles?.includes(Roles.user)) {
                return true
              } else if (roles?.includes(Roles.admin)) {
                return currentCompany?.role === Roles.admin
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        })
        .map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.SubMenu
              key={menu.key}
              icon={<Icon type={menu.icon} />}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={menu.key}
            >
              {menu.icon ? (
                <Icon
                  type={menu?.icon}
                />
              ) : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link onClick={() => closeMobileNav()} to={menu.path} />
              ) : null}
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

interface TopNavContentProps {
  topNavColor: string;
  localization: boolean;
}

const TopNavContent = (props: TopNavContentProps) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

interface Props {
  localization: boolean;
  type: NavType;
  routeInfo: NavItem;
  hideGroupTitle?: boolean;
}

const MenuContent = (props: Props) => {
  const sideNavTheme = useStoreState((state) => state.theme.sideNavTheme);
  const topNavColor = useStoreState((state) => state.theme.topNavColor);
  const onMobileNavToggle = useStoreActions(
    (actions) => actions.theme.toggleMobileNav
  );

  return props.type === NavType.SIDE ? (
    <SideNavContent
      {...props}
      onMobileNavToggle={onMobileNavToggle}
      sideNavTheme={sideNavTheme}
    />
  ) : (
    <TopNavContent topNavColor={topNavColor} {...props} />
  );
};

export default MenuContent;
