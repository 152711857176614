import { 
  ShopOutlined,
  UserOutlined,
  PlayCircleOutlined,
  BankOutlined,
  DropboxOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { Roles, AccountTypes } from 'types'

export interface MenuItem {
  key: string;
  path: string;
  title: string;
  icon: any;
  breadcrumb: boolean;
  roles?: Roles[];
  accountTypes: AccountTypes[];
}

export interface SubMenuItem extends MenuItem {
  submenu: MenuItem[]
}

export interface NavItem extends MenuItem {
  submenu: SubMenuItem[]
}

export type NavTree = NavItem[]

const userOnlyItems: NavItem[] = [{
  key: 'evidenceManagement',
  path: `${APP_PREFIX_PATH}/evidence/list`,
  title: 'Evidence Management',
  icon: PlayCircleOutlined,
  breadcrumb: false,
  submenu: [],
  roles: [Roles.admin, Roles.user],
  accountTypes: [AccountTypes.company]
}, {
  key: 'portalUsers',
  path: `${APP_PREFIX_PATH}/portal-users/list`,
  title: 'Portal Users',
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [],
  roles: [Roles.admin],
  accountTypes: [AccountTypes.company]
}]

const adminOnlyItems: NavItem[] = [{
  key: 'companies',
  path: `${APP_PREFIX_PATH}/companies`,
  title: 'Companies',
  icon: BankOutlined,
  breadcrumb: true,
  submenu: [],
  accountTypes: [AccountTypes.admin]

}, {
  key: 'schemes',
  path: `${APP_PREFIX_PATH}/schemes`,
  title: 'ShopSafe Schemes',
  icon: ShopOutlined,
  breadcrumb: true,
  submenu: [],
  accountTypes: [AccountTypes.admin]
},{
  key: 'staff',
  path: `${APP_PREFIX_PATH}/staff`,
  title: 'GoTalk Staff',
  icon: UserOutlined,
  breadcrumb: true,
  submenu: [],
  accountTypes: [AccountTypes.admin]
},
{
  key: 'stock',
  path: `${APP_PREFIX_PATH}/stock`,
  title: 'Stock Management',
  icon: DropboxOutlined,
  breadcrumb: true,
  submenu: [{
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/stock`,
    title: 'Dashboard',
    icon: DropboxOutlined,
    breadcrumb: true,
    submenu: [],
    accountTypes: [AccountTypes.admin]
  }, {
    key: 'stockTake',
    path: `${APP_PREFIX_PATH}/stock/stock-takes`,
    title: 'Stock Takes',
    icon: DropboxOutlined,
    breadcrumb: true,
    submenu: [],
    accountTypes: [AccountTypes.admin]
  }, {
    key: 'stockItems',
    path: `${APP_PREFIX_PATH}/stock/items`,
    title: 'Stock Items',
    icon: DropboxOutlined,
    breadcrumb: true,
    submenu: [],
    accountTypes: [AccountTypes.admin]
  }, {
    key: 'models',
    path: `${APP_PREFIX_PATH}/stock/models`,
    title: 'Models',
    icon: DropboxOutlined,
    breadcrumb: true,
    submenu: [],
    accountTypes: [AccountTypes.admin]
  }, {
    key: 'manufacturers',
    path: `${APP_PREFIX_PATH}/stock/manufacturers`,
    title: 'Manufacturers',
    icon: DropboxOutlined,
    breadcrumb: true,
    submenu: [],
    accountTypes: [AccountTypes.admin]
  },],
  accountTypes: [AccountTypes.admin]
}]

const navigationConfig = [
  ...adminOnlyItems,
  ...userOnlyItems
]

export default navigationConfig;
