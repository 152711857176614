import { useMutation, useLazyQuery } from "@apollo/client";
import { useStoreActions, SetUserPayload } from "state";
import jwtDecode from "jwt-decode";
import LogRocket from 'logrocket';

import {
  SignIn,
  SignInArgs,
  SignInRes,
  CurrentUser,
  CurrentUserRes,
} from "graphql-src/auth";

interface DecodedToken {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

const useAuth = () => {
  const authenticated = useStoreActions(
    (actions) => actions.auth.authenticated
  );
  const expired = useStoreActions((actions) => actions.auth.expired);
  const handleSignOut = useStoreActions((actions) => actions.auth.signOut);
  const setUser = useStoreActions((actions) => actions.user.setUser);
  const clearUser = useStoreActions((actions) => actions.user.clearUser);
  const setAuthMessage = useStoreActions(
    (actions) => actions.auth.setAuthMessage
  );

  interface HandleSuccessArgs extends SetUserPayload {
    access_token: string;
  }

  const handleSuccess = ({ id, access_token, admin, name, email, picture, companies }: HandleSuccessArgs) => {
    window.localStorage.setItem("access_token", access_token);

    LogRocket.identify(id, {
      name,
      email,
      userType: admin ? 'Staff' : 'User'
    });

    setUser({
      id,
      email,
      name,
      picture,
      admin,
      companies
    });
    authenticated(access_token);
  };

  const rehydrateAuth = () => {
    const idToken = localStorage.getItem("id_token");

    if (!!idToken) {
      let token: DecodedToken = jwtDecode(idToken);
      if (new Date().getTime() < token.exp * 1000) {
        if (
          token.iss === "https://gotalk.eu.auth0.com/" ||
          token.iss === "https://rtg.eu.auth0.com/"
        ) {
          getCurrentUser();
        } else {
          expired();
        }
      } else {
        expired();
      }
    } else {
      expired();
    }
  };

  interface OnLoginSuccessArgs extends SetUserPayload {
    access_token: string;
    id_token: string;
  }

  const onLoginSuccess = (data:OnLoginSuccessArgs) => {
    window.localStorage.setItem("access_token", data.access_token);
    window.localStorage.setItem("id_token", data.id_token);

    LogRocket.identify(data.id, {
      name: data.name,
      email: data.email,
      userType: data.admin ? 'Staff' : 'User'
    });

    handleSuccess({
      id: data.id,
      access_token: data.access_token,
      admin: data.admin,
      email: data.email,
      name: data.name,
      picture: data.picture,
      companies: data.companies
    });
  }

  const [handleLogin] = useMutation<SignInRes, SignInArgs>(SignIn, {
    onCompleted: ({ signIn }) => {
      onLoginSuccess(signIn)
    },
    onError: (error) => setAuthMessage(error.message),
  });

  const [getCurrentUser] = useLazyQuery<CurrentUserRes>(CurrentUser, {
    onCompleted: ({ currentUser }) => {
      handleSuccess({
        id: currentUser.id,
        admin: currentUser.admin,
        email: currentUser.email,
        name: currentUser.name,
        access_token: window.localStorage.getItem("access_token")!,
        picture: currentUser.picture,
        companies: currentUser.companies
      });
    },
    onError: (error) => expired(),
  });

  interface LoginArgs {
    email: string;
    password: string;
  }

  const login = ({ email, password }: LoginArgs) => {
    handleLogin({
      variables: {
        email,
        password,
      },
    });
  };

  const signOut = () => {
    clearUser();
    handleSignOut();
    window.localStorage.removeItem("access_token");
  };

  return {
    login,
    rehydrateAuth,
    signOut,
    onLoginSuccess,
    getCurrentUser
  };
};

export default useAuth;
