import { 
  action,
  Action,
} from 'easy-peasy'

export enum NavType {
  SIDE = 'SIDE',
  TOP = 'TOP'
}

export enum SideNavTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export const ThemeConfig = {
  navCollapsed: false,
	sideNavTheme: SideNavTheme.LIGHT,
	locale: 'en',
	navType: NavType.SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
}

export interface ThemeModel {
  navCollapsed: boolean;
	sideNavTheme: SideNavTheme;
	locale: string;
	navType: NavType,
	topNavColor: string;
	headerNavColor: string;
	mobileNav: boolean,
	currentTheme: string;

  toggleCollapsedNav: Action<ThemeModel, boolean>;
  sideNavStyleChange: Action<ThemeModel, SideNavTheme>;
  changeLocale: Action<ThemeModel, string>;
  navTypeChange: Action<ThemeModel, NavType>;
  topNavColorChange: Action<ThemeModel, string>;
  headerNavColorChange: Action<ThemeModel, string>;
  toggleMobileNav: Action<ThemeModel, boolean>;
  switchTheme: Action<ThemeModel, string>;
}

const themeModel:ThemeModel = {
  ...ThemeConfig,

  toggleCollapsedNav: action((state, payload) => {
    state.navCollapsed = payload
  }),
  sideNavStyleChange: action((state, payload) => {
    state.sideNavTheme = payload
  }),
  changeLocale: action((state, payload) => {
    state.locale = payload
  }),
  navTypeChange: action((state, payload) => {
    state.navType = payload
  }),
  topNavColorChange: action((state, payload) => {
    state.topNavColor = payload
  }),
  headerNavColorChange: action((state, payload) => {
    state.headerNavColor = payload
  }),
  toggleMobileNav: action((state, payload) => {
    state.mobileNav = payload
  }),
  switchTheme: action((state, payload) => {
    state.currentTheme = payload
  })  
}

export default themeModel