import { gql } from '@apollo/client'

export const CurrentUser  = gql`
  query currentUser {
    currentUser {
      id
      name
      email
      admin
      picture
      companies {
        id
        role
        company {
          id
          name
          demGroups {
            id
            groupId
          }
        }
      }
    }
  }
`


export interface CurrentUserRes {
  currentUser: {
    id: string;
    name: string;
    email: string;
    admin: boolean;
    picture: string;
    companies: {
      id: string;
      role: string;
      company: {
        id: string;
        name: string;
        demGroups: {
          id: string;
          groupId: string
        }[]
      }
    }[]
  }
}