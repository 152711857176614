import React from "react";
import {
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import utils from "utils";
import { Grid } from "antd";
import { useStoreState, NavType } from "state";

const { useBreakpoint } = Grid;

interface GetLogoWithGutterArgs {
  navCollapsed: boolean;
  navType: NavType;
  isMobile: boolean;
  mobileLogo?: boolean;
}

const getLogoWidthGutter = ({
  navCollapsed,
  navType,
  mobileLogo,
  isMobile,
}: GetLogoWithGutterArgs) => {
  const isNavTop = navType === NavType.TOP ? true : false;
  if (isMobile && !mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${120}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

interface GetLogoArgs {
  navCollapsed: boolean;
  logoType?: string;
}

const getLogo = (props: GetLogoArgs) => {
  const { navCollapsed, logoType } = props;
  if (logoType === "light") {
    if (navCollapsed) {
      return "/img/logo-sm-white.png";
    }
    return "/img/logo-white.png";
  }

  if (navCollapsed) {
    return "/img/logo-sm.png";
  }
  return "/img/logo.png";
};

interface GetLogoDisplayArgs {
  isMobile: boolean;
  mobileLogo?: boolean;
}

const getLogoDisplay = ({ isMobile, mobileLogo }: GetLogoDisplayArgs) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

interface Props {
  mobileLogo?: boolean;
  logoType?: string;
}

export const Logo = (props: Props) => {
  const navType = useStoreState((state) => state.theme.navType);
  const navCollapsed = useStoreState((state) => state.theme.navCollapsed);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");

  return (
    <div
      className={getLogoDisplay({ isMobile, mobileLogo: props.mobileLogo })}
      style={{
        width: `${getLogoWidthGutter({
          navType,
          navCollapsed,
          isMobile,
          mobileLogo: props.mobileLogo,
        })}`,
      }}
    >
      <img
        src={getLogo({ logoType: props.logoType, navCollapsed })}
        alt={`${APP_NAME} logo`}
      />
    </div>
  );
};

export default Logo;
